<template>
  <div class="feature">
    <div class="feature-container">
      <div class="content">
        <h1>{{ $t('feature.title') }}</h1>
        <p>{{ $t('feature.subtitle') }}</p>
      </div>
      <div class="cards">
        <Card
          :icon="feature.icon"
          :description="feature.description"
          v-for="(feature, index) in features.slice(0, 6)"
          :key="index"
        />
      </div>

      <b-collapse
        :open="false"
        position="is-bottom"
        aria-id="contentIdForA11y4"
        class="hero-collapse"
      >
        <template #trigger="props">
          <b-button
            aria-controls="contentIdForA11y4"
            :aria-expanded="props.open"
            :icon-right="!props.open ? 'chevron-down' : 'chevron-up'"
          >
            {{ !props.open ? $t('feature.buttonShow') : $t('feature.buttonHidden') }}
          </b-button>
        </template>
        <div class="cards">
          <Card
            :icon="feature.icon"
            :description="feature.description"
            v-for="(feature, index) in features.slice(6)"
            :key="index"
          />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';
import { features } from './FeaturesData.js';

export default {
  components: {
    Card
  },
  data() {
    return {
      features,
      isOpen: 0,
      collapses: [
        {
          title: 'Title 1',
          text: 'Text 1'
        },
        {
          title: 'Title 2',
          text: 'Text 2'
        },
        {
          title: 'Title 3',
          text: 'Text 3'
        }
      ]
    };
  }
};
</script>

<style lang="sass" scoped>
.feature
  min-width: 100%
  z-index: 100
  .feature-container
    background-image: url(../../assets/img/Pills.svg), url(../../assets/img/Pills.svg)
    background-position: 0% 40px, 100% 600px
    background-repeat: no-repeat,no-repeat
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    margin: 0 auto 3.7rem
    max-width: $bp-lg
    padding: 0 2rem
    @include rescale($bp-xl)
      max-width: $bp-xxl
  .content
    text-align: center
    font-size: 16px
    h1
      font-size: 2.2rem
      font-weight: 800
      color: $dark-blue
      @include rescale($bp-md)
        font-size: 3.75rem
        margin-bottom: 1.5rem
      @include rescale($bp-xxl)
        font-size: 3.75rem
    @include rescale($bp-md)
      font-size: 18px
      margin-bottom: 3rem
  .cards
    width: 100%
    flex-wrap: wrap
    display: flex
    @include rescale($bp-md)
      justify-content: center
  .hero-collapse
    flex-direction: column
    display: flex
    width: 100%
    align-items: center
    ::v-deep
      .collapse-content
        display: block
        width: 100%
      button
        margin-top: 1rem
        color: $blue-500
        border: 1px solid $blue-500 !important
        .icon
          margin-left: 0.5rem
          display: flex
        @include rescale($bp-md)
          margin-top: 3rem
          margin-bottom: 3rem
</style>
