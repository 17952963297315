export const features = [
  { icon: 'timer-outline', description: 'feature.cards.timerOutline' },
  { icon: 'truck-outline', description: 'feature.cards.truckOutline' },
  { icon: 'package', description: 'feature.cards.package' },
  { icon: 'text-box-check-outline', description: 'feature.cards.textBoxCheckOutline' },
  { icon: 'qrcode-scan', description: 'feature.cards.qrcodeScan' },
  { icon: 'archive-arrow-up-outline', description: 'feature.cards.archiveArrow' },
  { icon: 'package-return', description: 'feature.cards.packageReturn' },
  { icon: 'signature-image', description: 'feature.cards.signatureImage' },
  { icon: 'silverware-fork-knife', description: 'feature.cards.silverwareForkKnife' },
  { icon: 'archive-plus-outline', description: 'feature.cards.archivePlusOutline' },
  { icon: 'close-circle-outline', description: 'feature.cards.closeCircleOutline' },
  { icon: 'crosshairs-gps', description: 'feature.cards.crosshair' },
  {
    icon: 'cellphone-marker',
    description: 'feature.cards.cellphoneMarker'
  }
];
