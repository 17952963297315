<template>
  <div class="card">
    <div class="card-container">
      <b-icon v-if="icon !== 'package-return'" :icon="icon" size="is-small" class="icon" />
      <div v-else class="package-container">
        <b-icon icon="package-variant" size="is-small" class="package" />
        <b-icon icon="subdirectory-arrow-left" size="is-small" class="arrow" />
      </div>
      <p>{{ $t(description) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  width: 100%
  height: 124px
  margin: 0 auto 2rem auto
  border-radius: 1rem
  .card-container
    text-align: center
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 1.5rem 1rem
    @include rescale($bp-md + 72px)
      justify-content: flex-start
      flex-direction: column
      align-items: start
      padding: 2.75rem 2rem 0
  .icon
    font-size: 28px
    margin-bottom: 1rem
    color: $blue-100
    position: relative
    @include rescale($bp-md + 72)
      margin-bottom: 2.5rem
  .package-container
    margin-bottom: 1rem
    height: 16px
    color: $blue-100
    position: relative
    .package
      font-size: 28px
      margin: 0
    .arrow
      position: absolute
      margin: 0
      top: 0.6rem
      left: 0.7rem
      font-size: 16px
    @include rescale($bp-md + 72px)
     margin-bottom: 2.5rem
  p
    color: $dark-blue
    font-size: 16px
    @include rescale($bp-md + 72px)
      font-size: 24px
      text-align: initial
  @include rescale($bp-sm)
    width: 48%
  @include rescale($bp-md + 72px)
    width: 350px
    margin: 0 2% 2rem 2%
    height: 265px
</style>
