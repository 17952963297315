<template>
  <div class="layout">
    <Header />
    <slot />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';

export default {
  components: {
    Header,

    Footer
  },
  methods: {
    setLang(value) {
      this.$i18n.locale = value;
      this.$store.dispatch('LANG', value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
