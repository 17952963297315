<template>
  <div class="header">
    <div class="header-container">
      <router-link to="/">
        <img class="logo" src="@/assets/img/logo.svg" alt="CFS logo" />
      </router-link>
      <div class="menu-container">
        <p class="mr-3">{{ $t('navbar.messageNavbar') }}</p>
        <b-dropdown v-model="language" aria-role="list" position="is-bottom-left" class="dropdown">
          <template v-if="language" #trigger>
            <b-button label="EN" type="is-primary" icon-right="chevron-down" icon-left="web" />
          </template>
          <template v-else #trigger>
            <b-button label="ES" type="is-primary" icon-right="chevron-down" icon-left="web" />
          </template>
          <b-dropdown-item :value="true" aria-role="listitem">
            <h3>{{ $t('navbar.english') }}</h3>
          </b-dropdown-item>
          <b-dropdown-item :value="false" aria-role="listitem">
            <h3>{{ $t('navbar.spanish') }}</h3>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: true
    };
  },
  methods: {
    setLang(value) {
      this.$i18n.locale = value;
      this.$store.dispatch('LANG', value);
    }
  },
  watch: {
    language(value) {
      value ? this.setLang('en') : this.setLang('es');
    }
  }
};
</script>

<style lang="sass" scoped>
.header
  width: 100%
  z-index: 100
  .header-container
    align-items: center
    display: flex
    flex-direction: row
    height: 6rem
    justify-content: space-between
    margin: 0.7rem auto 0
    padding: 0 2rem
    text-align: right
    @include rescale($bp-md)
      max-width: $bp-lg
    @include rescale($bp-xl)
      max-width: $bp-xl
  .logo
    height: 35px
    @include rescale($bp-md)
      height: 48px
  .menu-container
    align-items: center
    display: flex
    p
      color: $black
      font-size: 12px
      font-weight: 800
      @include rescale($bp-sm)
        font-size: 18px
    button
      background: $white !important
      border: 1px solid $blue-500
      color: $blue-500
      size: 14px
      ::v-deep
        .is-small
          font-size: 20px
  .dropdown
    ::v-deep
        .dropdown-menu
          min-width: auto
          text-align: left
</style>
