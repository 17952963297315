<template>
  <div class="home">
    <Layout>
      <Hero />
      <Features />
    </Layout>
  </div>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import Features from '@/components/Features/Features.vue';
import Layout from '@/layout';

export default {
  components: {
    Hero,
    Features,
    Layout
  }
};
</script>

<style lang="scss" scoped></style>
