<template>
  <div class="privacy">
    <Layout>
      <Privacy />
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layout';
import Privacy from '@/components/Privacy/Privacy.vue';

export default {
  components: {
    Layout,
    Privacy
  }
};
</script>

<style lang="scss" scoped></style>
